<template>
  <div id="app">
    <div class="content">
      <div class="content_input">
        <div class="sidebar-logo-container">
          <transition name="sidebarLogoFade">
            <router-link key="expand" class="sidebar-logo-link" to="/">
              <img v-if="logo" :src="logo" class="sidebar-logo">
              <h1 class="sidebar-title">{{ title }} </h1>
            </router-link>
          </transition>
        </div>
        <el-input v-model="loginForm.username" clearable placeholder="用户名"></el-input>
        <el-input v-model="loginForm.password" clearable show-password placeholder="密码"></el-input>
        <div class="content_button">
          <el-button type="primary" @click="login">登录</el-button>
        </div>
      </div>
    </div>
    <div class="footer">
      © 2019-{{ new Date().getFullYear() }} <a style="text-decoration: none;" href="https://beian.miit.gov.cn/"
                                               target="_blank">湘ICP备2021018656号-1</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login_V2",
  data() {
    return {
      logo: 'https://wpimg.wallstcn.com/69a1c46c-eb1c-4b46-8bd4-e9e686ef5251.png',
      // logo: 'https://www.baidu.com/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png',
      title: 'SQuid-Element',
      loginForm: {
        username: 'Frank',
        password: '123456'
      },
    }
  },
  methods: {
    login() {
      let that = this;
      let username = that.loginForm.username;
      let password = that.loginForm.password;
      if (!username) {
        this.$notify.error({
          title: '错误',
          message: '用户名不能为空'
        });
      } else if (!password) {
        this.$notify.error({
          title: '错误',
          message: '密码不能为空'
        });
      } else {
        this.$store.dispatch('login', this.loginForm).then(() => {
          this.$router.push({path: this.redirect || '/'})
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }
    }
  },
  created() {
    require("../static/js/ribbon")
  }
}
</script>

<style src="../static/css/style.css" scoped/>
<style scoped lang="scss">
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 99.7%;
  height: 80px;
  line-height: 60px;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 55px;
      height: 55px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #2b2f3a;
      font-weight: 600;
      line-height: 50px;
      font-size: 20px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}

.footer {
  box-sizing: border-box;
  padding: 0 50px;
  position: absolute;
  top: 98%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: mymove 1s ease-in-out alternate;
  overflow: hidden;
  transition: 1.5s;
  font-size: 12px;
}
</style>
